<template>
  <section id="land-user-login">
    <land-img
      :height="minorHeight"
      :src="minor.cover"
      flat
      max-width="100%"
      tile
    >
      <land-section
        id="land-user-login-major"
        :space="space || 2"
      >
        <v-container
          style="max-width: 1200px; padding: 0 16px;"
        >
          <v-row
            no-gutters
          >
            <v-col
              cols="12"
              md="7"
              class="white hidden-sm-and-down"
            >
              <login-left />
            </v-col>
            <v-col
              class="white"
              cols="12"
              md="5"
              sm="12"
            >
              <template v-if="curMode === 'qrcode'">
                <login-qrcode @change="onChangeMode" />
              </template>
              <template v-else-if="curMode === 'phone'">
                <login-phone
                  @change-password="changePassword"
                  @change="onChangeMode"
                />
              </template>
              <template v-else-if="curMode === 'changePassword'">
                <change-password
                  @login="toPhoneLogin"
                />
              </template>
            </v-col>
          </v-row>
        </v-container>
      </land-section>
    </land-img>
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'

  const DEF_HT = 800

  export default {
    name: 'SectionUserLogin',
    components: {
      LoginLeft: () => import('@/pages/sections/my/LoginLeft.vue'),
      LoginPhone: () => import('@/pages/sections/my/LoginPhone.vue'),
      LoginQrcode: () => import('@/pages/sections/my/LoginQrcode.vue'),
      ChangePassword: () => import('@/pages/sections/my/ChangePassword.vue'),
    },
    props: {
      mode: {
        type: String,
        default: 'qrcode'
      }
    },
    data () {
      return {
        minor: {},
        curMode: 'qrcode',
        points: {
          xs: 640,
          sm: 640,
          md: DEF_HT,
        },
      }
    },
    computed: {
      gradient () {
        const overlay1 = 'rgba(255, 255, 255, 0.45)'
        const overlay2 = 'rgba(255, 255, 255, 0.65)'
        return `to top right, ${overlay1}, ${overlay2}`
      },
      space () {
        const ht = this.getHeight()
        const sp = Math.floor(ht * 0.15)
        return sp
      },
      minorHeight () {
        const ht = this.getHeight()
        return ht
      },
    },
    watch: {
      mode: {
        handler (val) {
          this.curMode = val
        },
        immediate: true
      }
    },
    created () {
      this.getData()
    },
    methods: {
      onChangeMode (ev) {
        this.curMode = ev.mode
      },
      changePassword (ev) {
        this.curMode = 'changePassword'
      },
      toPhoneLogin () {
        this.curMode = 'phone'
      },
      toQrcodeLogin () {
        this.curMode = 'qrcode'
      },
      getHeight () {
        const name = this.$vuetify.breakpoint.name
        const ht = this.points[name] || DEF_HT
        return ht
      },
      getData () {
        const me = this

        let params = null
        const executed = function (res) {
          if (res.status) {
            const items = params.items || []
            me.minor = Object.assign({}, items[0] || {})
          }
        }

        params = web.banner.getParams({
          type: web.comm.BannerTypes.LOGIN_MINOR,
          state: web.comm.States.PUBLISH,
          executed
        })
        api.httpx.getItems(params)
      },
    }
  }
</script>
